import React from "react";

import Container from "react-bootstrap/Container";
import Stack from "react-bootstrap/Stack";
import { FaTwitter } from "react-icons/fa";

const Footer = () => {
  return (
		<footer>
			<Container>
				<Stack direction="horizontal" gap={4}>
					<div>&copy;2021-2023 DogecoinWhaleAlert.com</div>
					<div className="ms-auto"><a href="//twitter.com/DogeWhaleAlert" target="_blank" rel="noopener noreferrer"><FaTwitter /></a></div>
				</Stack>
			</Container>
		</footer>
  );
}

export default Footer;
