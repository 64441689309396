import React from "react";

class Scripts extends React.Component {
  componentDidMount() {	
		setTimeout(function() { 
			const x = Math.floor(Math.random() * (9999999999-1000000000));

			const script = document.createElement("script");
			script.src = "/js/market_data.js?" + x;
			script.async = true;
			document.body.appendChild(script);

			const script2 = document.createElement("script");
			script2.src = "/js/charts.js?" + x;
			script2.async = true;
			document.body.appendChild(script2);
		}, 1000);		
	}

  render() {
    return true;
  }
}

export default Scripts;
