import React from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import ListGroup from 'react-bootstrap/ListGroup';
/*
import AdsTop from "./AdsTop";
import AdsBottom from "./AdsBottom";
*/
import Footer from "./Footer";
import Scripts from "./Scripts";

// These 3 compnents are the same, just different ways to write each
// 1. ES6 Class component
//class Welcome extends React.Component {
//  render() {
//    return <p className="lead">Welcome, {this.props.name}!</p>
//  }
//}

// 2. Function component
//function Welcome(props) {
//  return <p className="lead">Welcome, {props.name}!</p>
//}

// 3. Function component
//const Welcome = (props) => {
//  return <p className="lead">Welcome, {props.name}!</p>
//}

// Declare const
//const foo = "foo!";

const Main = () => {
  return (
    <>
      <main>
        <Container>
					<Row>
						<Col>
							<div className="box-tips">
								<strong>IMPORTANT NOTICE</strong>: The Ðogecoin Whale Alert service was retired 26 June 2023 and this site is no longer maintained. As a result, some data below may be inaccurate.
							</div>
						</Col>
					</Row>
					<Row>
						<Col xs={6} sm={4} md={2}>
							<div className="box-sp">
								<div>ÐOGE/USD</div>
								<div><span id="doge-usd"></span><span id="doge-usd-percent"></span></div>
								<div id="doge-usd-volume"></div>
							</div>
						</Col>
						<Col xs={6} sm={4} md={2}>
							<div className="box-sp">
								<div>ÐOGE/CAD</div>
								<div><span id="doge-cad"></span><span id="doge-cad-percent"></span></div>
								<div id="doge-cad-volume"></div>
							</div>
						</Col>
						<Col xs={6} sm={4} md={2}>
							<div className="box-sp">
								<div>ÐOGE/EUR</div>
								<div><span id="doge-eur"></span><span id="doge-eur-percent"></span></div>
								<div id="doge-eur-volume"></div>
							</div>
						</Col>
						<Col xs={6} sm={4} md={2}>
							<div className="box-sp">
								<div>ÐOGE/GBP</div>
								<div><span id="doge-gbp"></span><span id="doge-gbp-percent"></span></div>
								<div id="doge-gbp-volume"></div>
							</div>
						</Col>
						<Col xs={6} sm={4} md={2}>
							<div className="box-sp">
								<div>ÐOGE/BTC</div>
								<div><span id="doge-btc"></span><span id="doge-btc-percent"></span></div>
								<div id="doge-btc-volume"></div>
							</div>
						</Col>
						<Col xs={6} sm={4} md={2}>
							<div className="box-sp">
								<div>ÐOGE/ETH</div>
								<div><span id="doge-eth"></span><span id="doge-eth-percent"></span></div>
								<div id="doge-eth-volume"></div>
							</div>
						</Col>
          </Row>

					<Row lg={12}>
						<ListGroup horizontal>
							<ListGroup.Item>24H HIGH<div id="market-24h"></div></ListGroup.Item>
							<ListGroup.Item>24H LOW<div id="market-24l"></div></ListGroup.Item>
							<ListGroup.Item>ATH<div id="market-ath"></div></ListGroup.Item>
							<ListGroup.Item>MCAP<div id="market-mcap"></div></ListGroup.Item>
							<ListGroup.Item>RANK<div id="market-rank"></div></ListGroup.Item>
						</ListGroup>
					</Row>

					<Row className="mt-3">
						<Col xs={12} sm={6}>
							<div className="chart-left">
								<h2><img src="/images/robinhood.png" width="24" height="32" border="0" alt="" /> Robinhood Wallets</h2>
								<p>Robinhood's main wallet 3334959 contains 8 known addresses. Their second wallet, 1699275, contains multiple addresses with a combined balance of just 927 Ðogecoin (as of June 2023).</p>
								<p>The amount of Ðogecoin held by Robinhood on behalf of its retail investors amounts to approximately <span id="sum"></span>% of the circulating supply.</p>
								<Table size="sm">
									<thead>
										<tr>
											<th>#</th>
											<th>Wallet Address</th>
											<th className="text-right">ÐOGE</th>
											<th className="text-right hide-sm">USD</th>
											<th className="text-right">%CS</th>
										</tr>
									</thead>
									<tbody>
										<tr id="wallet1">
										</tr>
										<tr id="wallet2">
										</tr>
										<tr id="wallet3">
										</tr>
										<tr id="wallet4">
										</tr>
										<tr id="wallet5">
										</tr>
										<tr id="wallet6">
										</tr>
										<tr id="wallet7">
										</tr>
										<tr id="wallet8">
										</tr>
										<tr id="wallet9">
										</tr>
										<tr id="wallet10">
										</tr>
										<tr id="totals">
										</tr>
									</tbody>
								</Table>
							
								<canvas id="rhwallets" width="1" height="1"></canvas>
							</div>
						</Col>
						<Col xs={12} sm={6}>
							<div className="chart-right">
								<h2><img src="/images/doge_black.svg" width="32" height="32" border="0" alt="" /> Supply and Issuance</h2>
								<p>Ðogecoin has been wrongly criticized for having "no cap" and many falsely assert it has an "unlimited supply."</p>
								<p>Ðogecoin has a fixed rate of issuance (inflation rate) and has been designed to issue 5 billion additional coins each year, allowing the total circulating supply to grow by 100B coins every 20 years.</p>
								<p>Ðogecoin's total circulating supply is currently <span id="cs"></span>.</p>
								<canvas id="supply" width="500" height="300"></canvas>
								<p>Ðogecoin has a low inflation rate, which makes it an ideal cryptocurrency and medium of exchange for day-to-day transactions. For example:</p>
								<ul>
									<li>In 2015, the total circulating supply was 100B with an issuance (inflation) rate of 5.00%.</li>
									<li>By 2035, the total supply will reach 200B with an issuance (inflation) rate of 2.56%.</li>
									<li>By 2055, the total supply will reach 300B with an issuance rate of 1.69%.</li>
									<li>By 2075, the total supply will reach 400B with an issuance rate of 1.27%.</li>
								</ul>
							</div>
						</Col>
					</Row>
				</Container>
					
				<section id="light">
					<Container>
						<Row>
							<Col>
								<h2>Much Transaction</h2>
							</Col>
						</Row>
							
						<Row>
							<Col>
								<p>Recent transactions of more than 5M ÐOGE.</p>
								<div id="tx-box">
									<div id="loading" className="black20">Loading...
										<span className="visually-hidden">Loading...</span>
									</div>
									<div id="tx"></div>
								</div>
							</Col>
						</Row>

						<Row>
							<Col>
								<h2>About</h2>
							</Col>
						</Row>
						
						<Row>
							<Col>
								<p>Ðogecoin Whale Alert tracks and publishes large (whale) Ðogecoin transactions of 5M ÐOGE or more. Our system analyzes real-time transactions on the ÐOGE blockchain 24/7/365 and translates them into tweets published at @DogeWhaleAlert, and stores the data in our databases.</p>
								<p>We are constantly working to improve our system to ensure accuracy, reliability, and to add new features.</p>
								<p>Please follow us on Twitter at @DogeWhaleAlert and let us know how we're doing! The DMs are open and your feedback matters!</p>
								<p>Ðogecoin Whale Alert is not associated with the official Ðogecoin project, nor its team of developers.</p>
								<p>Ðogecoin tips are much appreciated: <span className="sans-serif strong">DF2p4dcHhKSE9K6sQMWBXoRhsLRy69mKS5</span></p>
							</Col>
						</Row>
					</Container>
				</section>
      </main>

      <Footer />
			<Scripts />
    </>
  );
}

export default Main;
