import React from "react";

import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { FaTwitter } from "react-icons/fa";

const Navigation = () => {
  return (
    <Navbar collapseOnSelect expand="lg" bg="light" variant="light" fixed="top">
			<Container>
			<Navbar.Brand href="/"><img src="/images/doge.svg" width="35" height="35" className="d-inline-block" alt="Ðogecoin Whale Alert logo" /> Ðogecoin Whale Alert</Navbar.Brand>
			<Navbar.Toggle aria-controls="responsive-navbar-nav" />
			<Navbar.Collapse id="responsive-navbar-nav">
				<Nav className="ms-auto">
					<Nav.Link href="//twitter.com/DogeWhaleAlert" target="_blank" rel="noopener noreferrer"><FaTwitter /></Nav.Link>
				</Nav>
			</Navbar.Collapse>
			</Container>
		</Navbar>
  );
}

export default Navigation;
